.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.dropdown-menu {
  --bs-dropdown-bg: white;
}

.modal {
  --bs-modal-bg: #fff
}

:root {
  --bs-body-bg: #ffffff96;
}

.full-transparent {
  background-color: rgba(var(--bs-light-rgb), 0);
}

.semi-transparent bg-white {
  background-color: rgba(var(--bs-white-rgb), 0.9);
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.cnalcobendas {
    font-weight: bold;
}
